import React, { useEffect } from 'react'; // useState, 
import useGlobalState from '../hooks/useGlobalState';
import { Grid } from '@material-ui/core';
import Fraction from './Fraction';

// import ReactPlayer from 'react-player';
// import mainStyles from '../styles/mainStyles';

const Fractions = props => {

	const [state] = useGlobalState(); // , dispatch
	
	// let myPlayer = null;

	useEffect(() => {
		console.log('Fractions')
	}, [])

	const renderFractions = () => {
		let fractionCount = state.settings.fractions.length
		let gridSize = 12 / fractionCount
		return (
			<>
				{state.settings.fractions.map((f, index) => {
					return (
						<Grid key={f.uuid} item xs={gridSize}>
							<Fraction uuid={f.uuid} index={index} />
						</Grid>
					)
				})}
			</>
		)
	}

	return (
		<>
			<Grid container>
				{
					state.settings.fractions ? renderFractions() : ""
				}
			</Grid>
		</>
	)
}

export default Fractions;