import React, { useEffect } from 'react'; // useState, 
import { Redirect } from 'react-router-dom'; // Switch, Route, 
// import { useHistory } from 'react-router';
// import ReactPlayer from 'react-player';
import useGlobalState from '../hooks/useGlobalState';
// import cookie from 'react-cookies';
// import { Grid, Typography } from '@material-ui/core';
import Fractions from './Fractions';
import mainStyles from '../styles/mainStyles';

const { io } = require("socket.io-client");

const MainContainer = props => {
	const classes = mainStyles();
	// const history = useHistory();

	const [state, dispatch] = useGlobalState();

	useEffect(() => {
		if (state.session && state.settings) {
			const localSocket = io(state.settings.ioUrl, { auth: { token: state.session.token, private: state.session.uuid } });  // "http://localhost:2999/eco"
			localSocket.on('connect', () => {
				console.log('web conn', localSocket);
			});
			localSocket.on('nudge', (data) => {
				let packet = JSON.parse(data);
				dispatch({ activePacket: packet }); 
			});
		}
	// eslint-disable-next-line
	}, []);
	

	useEffect(() => {
		if (state.activePacket !== false) {
			activateNudge(state.activePacket)
		}
	// eslint-disable-next-line		
	}, [state.activePacket]);

	const activateNudge = (packet) => {
		if (state.activeNudge === false) {
			console.log('nudge', state.activeNudge)
			let newSettings = { ...state.settings }
			if (newSettings.fractions[packet.index].title === "") {
				newSettings.fractions[packet.index].title = packet.title
				newSettings.fractions[packet.index].text = packet.text
				newSettings.fractions[packet.index].text2 = packet.text2
				newSettings.fractions[newSettings.fractions[packet.index].videoNudge].videoLink = '/assets/video/' + packet.video
			}
			dispatch({ settings: newSettings, activeNudge: packet.index }); 
		} else {
			console.log('no nudge', state.activeNudge)
		}
	}
	
	useEffect(() => {
		if (!state.socket && state.session && state.settings) {
			// setSocket(state.settings.ioUrl, state.session.token, state.session.uuid)
		}
	// eslint-disable-next-line
	}, [state.socket]);

	// let myPlayer = null;

	// const _handleClick = async () => {
	// 	let count = state.badgeCount;
	// 	count++;
	// 	dispatch({ badgeCount: count });
	// 	console.log('Hej', count, cookie.load('SESSION'));
	// 	dispatch({ videoLink: 'http://localhost:3000/test2_640x1080.mp4' });
	// 	console.log('MyPlayer', myPlayer);
	// };
	// const _handleClick2 = async () => {
	// 	myPlayer.seekTo(10);
	// 	console.log(state.socket.id)
	// 	state.socket.emit("hello", "world from webclient: " + state.socket.id, (response) => {
	// 		console.log("callback fra server", response.status); // ok
	// 	  });
	// 	  dispatch({ videoLink: '' });
	// };
	// const _handleClick3 = async () => {
	// 	// console.log('MyPlayer', myPlayer.state);
	// 	// dispatch({ videoLink: 'http://localhost:3000/test1_640_1080.mp4' });
	// 	history.push('/load');

	// };
	//https://www.npmjs.com/package/react-player
	return (
		state.settings ?
			<>
				<div className={classes.mainBackground}>
					<div className={classes.mainContainer}>
						<Fractions />
						{/* <Grid container >
							<Grid item xs={4}>
								<ReactPlayer width={640} height="100%" ref={node => { myPlayer = node }} url={state.videoLink} playing={true} loop={true} muted={true} onProgress={(played, loaded) => { console.log(played, loaded) }} />
							</Grid>
							<Grid item xs={4}>
								<button onClick={() => _handleClick()}>Hvorfor er der ikke noget...</button>
								<button onClick={() => _handleClick2()}>Goto 10...</button>
								<button onClick={() => _handleClick3()}>Am i playing...</button>
							</Grid>
							<Grid item xs={4}>
								{ state.badgeCount === 10 ? <div>Hej</div> : "" }
								{ state.badgeCount === 11 ? <div>Hej med 11</div> : "" }
								<div><Typography>{state.badgeCount}</Typography></div>
							</Grid>
						</Grid> */}
					</div>
				</div>
			</>
			: <Redirect from={window.location.pathname} to={{
				pathname: '/load', state: {
					prevURL: window.location.pathname
				}
			}} />
	)
}

export default MainContainer;