import { createTheme } from '@material-ui/core/styles';

let defaultOptions = {
	theme: {
		light: {
			primary: '#000',
			secondary: '#191B32',
			hover: '#fff',
			headerBgColor: '#5D6A70',
			footerBgColor: '#5D6A70',
			sidebarColor: '#5D6A70',
			background: '#000',
			logo: undefined
		}
	}
}

const theme = (options = defaultOptions) => {
	return createTheme({
		palette: {
			primary: {
				main: options.theme.light.primary,
			}, secondary: {
				main: options.theme.light.secondary,
			},
		},
		hover: options.theme.light.hover,
		headerBg: options.theme.light.headerBgColor,
		headerColor: options.theme.light.headerColor,
		headerSecondaryBg: options.theme.light.headerSecondaryBgColor,
		headerSecondaryColor: options.theme.light.headerSecondaryColor,
		footerBg: options.theme.light.footerBgColor,
		sidebar: options.theme.light.sidebarColor,
		background: options.theme.light.background,
		toolbarBackground: options.theme.light.toolbarBg,
		toolbarIndicator: options.theme.light.toolbarIndicator,
		typography: {
			h2: {
				fontSize: '3.8rem',
				fontWeight: 'bold',
			},
			h3: {
				fontSize: '1.8rem',
			},
			h4: {
				fontSize: '1.6rem',
			},
			h5: {
				fontSize: '1.2rem',
			},
			h6: {
				fontSize: '1rem',
			},
			caption: {
				fontSize: '1.1rem',
				color: 'rgba(255, 255, 255, 0.90)',
			},
			body1: {
				color: '#fff',
				fontSize: '1.2rem',
			},
			body2: {
				color: '#fff',
				fontSize: '1.2rem',
			},
			button: {
				textTransform: "none"
			},
		},
		overrides: {
			MuiInputLabel: {
				root: {
					color: "#000"
				}
			},
			MuiDialogTitle: {
				root: {
					fontSize: "1.25rem",
					fontFamily: "Roboto, Helvetica, Arial",
					fontWeight: 500,
					lineHeight: 1.6,
					letterSpacing: "0.0075em",
					backgroundColor: '#5D6A70',
					color: 'white',
					padding: 16
				},

			},
			MuiCircularProgress: {
				colorPrimary: '#ff0'
			},
		}
	});
}

export default theme;