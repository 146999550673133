import { create } from 'apisauce';
import cookie from 'react-cookies';

export const coreApi = create({
	baseURL: 'https://services.senti.cloud/core',
	timout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
});

export const ecoChangeApi = create({
	// baseURL: 'http://localhost:3060',
	baseURL: 'https://dev.services.senti.cloud/ecochange',
	timout: 30000,
	headers: {
		'Accept': 'application/json',
		'Content-Type': 'application/json'
	}
});


export const setToken = () => {
	try {
		let token = cookie.load('SESSION').token;
		coreApi.setHeader('Authorization', 'Bearer ' + token);
		ecoChangeApi.setHeader('Authorization', 'Bearer ' + token);
		return true;
	} catch (error) {
		return false;
	}
};

export const setHeaders = () => {
	coreApi.setHeader('wlHost', window.location.hostname);
	ecoChangeApi.setHeader('wlHost', window.location.hostname);
};

setToken();
setHeaders();
