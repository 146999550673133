import React, { useEffect } from 'react'; // useState, 
import useGlobalState from '../hooks/useGlobalState';
import ActiveCard from './ActiveCard';
import MarqueeNudging from './MarqueeNudging';
import ReactPlayer from 'react-player';
// import mainStyles from '../styles/mainStyles';

const Fraction = props => {
	const [state, dispatch] = useGlobalState(); // , dispatch
	
	useEffect(() => {
		console.log('Fraction')
	}, [])

	return (
		<>
			{ state.activeNudge === props.index ? <ActiveCard index={props.index}/> :  ""}
			{ state.settings.fractions[props.index].videoLink !== "" ? <ReactPlayer width="100%" height="1080px" url={state.settings.fractions[props.index].videoLink} playing={true} loop={false} onEnded={() => {
				console.log('end', state.activeNudge)
				if (state.activeNudge !== false) {
					let newSettings = { ...state.settings }
					newSettings.fractions[state.activeNudge].title = ""
					newSettings.fractions[state.activeNudge].text = ""
					newSettings.fractions[state.activeNudge].text2 = ""
					newSettings.fractions[newSettings.fractions[state.activeNudge].videoNudge].videoLink = ""
					dispatch({ settings: newSettings, activeNudge: false, activePacket: false }); 
				}
			}} muted={true} />
				: ""}
			<MarqueeNudging key={props.index} fraction={props.index} fractions={state.settings.fractions.length} items={state.images[state.settings.fractions[props.index].imagetype]} />
			{/* { state.activeNudge === false ? <MarqueeNudging key={"skod" + props.index} fractions={2} items={state.images['restaffald']} /> : ""} */}
			{/* <div>
				<Typography>
					Hello world {props.uuid} - {state.badgeCount} - {props.uuid}/{state.settings.fractions[props.index].uuid} - {props.index}<br/>
					{state.settings.fractions.filter(e => { return e.uuid === props.uuid })[0].videoLink}
					state.settings.fractions.filter(e => { return e.uuid === props.uuid })[0].videoLink
				</Typography>
			</div> */}
		</>
	)
}

export default Fraction;