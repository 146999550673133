import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Login from './Login';
import Load from './Load';
import MainContainer from 'components/MainContainer';

const Main = () => {
	return (
		<>
			<Switch>
				{/* <Route path={'/password/reset/:lang/:token?'}>
					<ForgotPassword />
				</Route>
				<Route path={'/password/confirm/:lang/:token'}>
					<ConfirmUser />
				</Route>
				<Route path={'/administration'}>
					<Administration />
				</Route> */}
				<Route path={'/login'}>
					<Login />
				</Route>
				<Route path={'/load'}>
					<Load />
				</Route>
				<Route path={'/'}>
					<MainContainer />
				</Route>
			</Switch>
		</>
	)
}

export default Main;