import React, { useState } from 'react';
import moment from 'moment';
import cookie from 'react-cookies';
import { useHistory, useLocation } from 'react-router';
// import { Redirect } from 'react-router-dom'; // Switch, Route, 
import { TextField, Button } from '@material-ui/core';
import CircularLoader from 'components/ui/CircularLoader';
// import GoogleLogin from 'react-google-login';

import loginStyles from '../styles/loginStyles';
import useEventListener from '../hooks/useEventListener';
import useGlobalState from '../hooks/useGlobalState';

import { setToken } from '../data/api';
import { loginUser } from '../data/coreApi';//loginGoogleUser
// import Google from 'assets/google.svg';

const Login = () => {

	const classes = loginStyles();
	const location = useLocation();
	const history = useHistory();

	const [, dispatch] = useGlobalState();

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [loggingIn, setLoggingIn] = useState(false);
	const [errorMsg, setErrorMsg] = useState('');


	const hangleLogin = async () => {
		console.log('Handle Login');
		setLoggingIn(true);

		setErrorMsg('');

		await loginUser(username, password).then(async rs => {
			if (rs) {
				if (rs.error) {
					let msg = '';
					switch (rs.error) {
						case 'login.accountDeleted':
							msg = 'Kontoen er slettet.';
							break;
						case 'login.accountNotActive':
							msg = 'Kontoen er ikke aktiveret.';
							break;
						case 'login.accountDeactivated':
							msg = 'Kontoen er deaktiveret.';
							break;
						case 'login.wrongPassword':
							msg = 'Der er indtastet et forkert kodeord.';
							break;
						case 'login.accountNotFound':
							msg = 'Kontoen blev ikke fundet.';
							break;
						case 'login.missingOrganisation':
							msg = 'Der er ikke indtastet en organisation.';
							break;
						case 'login.missingUsername':
							msg = 'Der er ikke indtastet et brugernavn.';
							break;
						case 'login.missingPassword':
							msg = 'Der er ikke indtastet et kodeord.';
							break;
						default:
						case 'login.unknownError':
							msg = 'Der opstod en uventet fejl, prøv venligst igen.';
							break;
					}

					setErrorMsg(msg);
					setLoggingIn(false);
				} else {
					let expires = moment().add('1', 'year');
					cookie.save('SESSION', rs, { path: '/', expires: expires.toDate() });
					dispatch({ session: rs });

					if (setToken()) {
						var prevURL = location.state ? location.state.prevURL : null;
						history.push(prevURL ? prevURL : '/load');
					}
				}
			} else {
				setErrorMsg('Kunne ikke logge ind.');
				setLoggingIn(false);
			}
		});
	};

	const handleKeyPress = (event) => {
		if (event.key === 'Enter') {
			hangleLogin();
		}
	};

	useEventListener('keypress', handleKeyPress);
	
	return (
		<>
			<div className={classes.loginBackground}>
				<div className={classes.centerWrapper}>
					<div className={classes.loginWrapper}>
						<div className={classes.logo}>
							<img src='/assets/ecochange-logo.png' style={{ maxWidth: '80%' }} alt="EcoChange Logo" />
						</div>

						{errorMsg ? <div className={classes.errorMsg}>{errorMsg}</div> : ""}

						{!loggingIn ?
							<>
								<TextField
									id="username"
									autoFocus
									onChange={(event) => setUsername(event.target.value)}
									className={classes.textfield}
									label="Brugernavn"
									variant="outlined"
									error={errorMsg.length ? true : false}
								/>

								<TextField
									id="password"
									type="password"
									onChange={(event) => setPassword(event.target.value)}
									className={classes.textfield}
									label="Kodeord"
									variant="outlined"
									error={errorMsg.length ? true : false}
								/>

								<div className={classes.buttonWrapper}>
									<Button
										variant="contained"
										color="primary"
										className={classes.button}
										disabled={loggingIn}
										onClick={hangleLogin}>
										Log ind
									</Button>
								</div>
							</>
							: <CircularLoader />}
					</div>

					<div>
						<div className={classes.copyright}>
							©2016-{moment().year()} Alle rettigheder forbeholdt.
							<br />
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Login;