import React from 'react';
import { MuiThemeProvider } from '@material-ui/core';

import theme from 'styles/theme';

const ThemeProvider = (props) => {
	return (
		<MuiThemeProvider theme={theme(undefined)}>
			{props.children}
		</MuiThemeProvider>
	)
}

export default ThemeProvider;