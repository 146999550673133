import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom'; // Switch, Route, 
import { useHistory } from 'react-router';
import loadStyles from '../styles/loadStyles';
import CircularLoader from 'components/ui/CircularLoader';

// import loginStyles from '../styles/loginStyles';
import useGlobalState from '../hooks/useGlobalState';
import cookie from 'react-cookies';
import { Typography } from '@material-ui/core';
import { ecoChangeApi } from 'data/api';

const Load = () => {
	const history = useHistory();
	const classes = loadStyles();
	const [state, dispatch] = useGlobalState();

	console.log(state)

	useEffect(() => {
		const loadSettings = async () => {
			let data = await ecoChangeApi.get('/v2/eco/config').then(rs => rs.data);
			console.log(data)
			dispatch({ 
				settings: data
			});
		}
		if (!state.session) {
			let localSession = cookie.load('SESSION')
			if (localSession !== undefined) {
				dispatch({ session: localSession })
			}
		}
		if (!state.settings && state.session !== false) {
			loadSettings();
		}

		if (state.settings && state.session) {
			console.log('settings loaded...', state.settings, state.session)
			setTimeout(() => { 
				history.push('/');
			}, 1000)
		}
	}, [dispatch, state, history]);

	return (
		cookie.load('SESSION') ?
			<>
				<div className={classes.loadBackground}>
					<div className={classes.loadContainer}>
						<Typography variant={'caption'}>Loading...</Typography>
						<CircularLoader />
					</div>
				</div>
			</>
			: <Redirect from={window.location.pathname} to={{
				pathname: '/login', state: {
					prevURL: window.location.pathname
				}
			}} />
	)
}

export default Load;