import React from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';

import GlobalStateProvider from './GlobalStateProvider';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import { CssBaseline } from '@material-ui/core';

import ThemeProvider from './ThemeProvider';

export const hist = createBrowserHistory();

const Providers = props => {
	return (
		<GlobalStateProvider>
			<ThemeProvider>
				<CssBaseline>
					<MuiPickersUtilsProvider utils={MomentUtils}>
						<Router history={hist} key={Math.random()}>
							{props.children}
						</Router>
					</MuiPickersUtilsProvider>
				</CssBaseline>
			</ThemeProvider>
		</GlobalStateProvider>
	)
}

export default Providers;