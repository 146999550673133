import React from 'react';
// import cookie from 'react-cookies';

const defaultGlobalState = {
	session: false,
	settings: false,
	socket: false,
	badgeCount: 10,
	videoLink: null,
	actionCard: [],
	activeNudge: false,
	activePacket: false,
	images: {
		'madspild': [
			'/assets/images/madspild/1.png',
			'/assets/images/madspild/piktogram.png',
			'/assets/images/madspild/2.png',
			'/assets/images/madspild/3.png',
			'/assets/images/madspild/4.png',
			'/assets/images/madspild/piktogram.png',
			'/assets/images/madspild/5.png',
			'/assets/images/madspild/6.png',
			'/assets/images/madspild/7.png',
			'/assets/images/madspild/piktogram.png',
			'/assets/images/madspild/8.png',
			'/assets/images/madspild/9.png',
			'/assets/images/madspild/10.png',
			'/assets/images/madspild/piktogram.png',
			'/assets/images/madspild/11.png',
			'/assets/images/madspild/12.png',
			'/assets/images/madspild/13.png',
			'/assets/images/madspild/piktogram.png',
			'/assets/images/madspild/14.png',
			'/assets/images/madspild/15.png',
			'/assets/images/madspild/16.png',
			'/assets/images/madspild/piktogram.png',
			'/assets/images/madspild/17.png',
			'/assets/images/madspild/18.png',
			'/assets/images/madspild/19.png',
			'/assets/images/madspild/piktogram.png',
			'/assets/images/madspild/20.png',
			'/assets/images/madspild/21.png',
			'/assets/images/madspild/22.png',
			'/assets/images/madspild/piktogram.png',
			'/assets/images/madspild/23.png',
			'/assets/images/madspild/24.png',
			'/assets/images/madspild/25.png',
			'/assets/images/madspild/piktogram.png',
		],
		'restaffald': [
			'/assets/images/restaffald/1.png',
			'/assets/images/restaffald/2.png',
			'/assets/images/restaffald/3.png',
			'/assets/images/restaffald/piktogram.png',
			'/assets/images/restaffald/4.png',
			'/assets/images/restaffald/5.png',
			'/assets/images/restaffald/6.png',
			'/assets/images/restaffald/piktogram.png',
			'/assets/images/restaffald/7.png',
			'/assets/images/restaffald/8.png',
			'/assets/images/restaffald/9.png',
			'/assets/images/restaffald/piktogram.png',
			'/assets/images/restaffald/10.png',
			'/assets/images/restaffald/11.png',
			'/assets/images/restaffald/12.png',
			'/assets/images/restaffald/piktogram.png',
			'/assets/images/restaffald/13.png',
			'/assets/images/restaffald/14.png',
			'/assets/images/restaffald/15.png',
			'/assets/images/restaffald/piktogram.png',
			'/assets/images/restaffald/16.png',
			'/assets/images/restaffald/17.png',
			'/assets/images/restaffald/18.png',
			'/assets/images/restaffald/piktogram.png',
			'/assets/images/restaffald/19.png',
			'/assets/images/restaffald/20.png',
			'/assets/images/restaffald/21.png',
			'/assets/images/restaffald/piktogram.png',
			'/assets/images/restaffald/22.png',
			'/assets/images/restaffald/23.png',
			'/assets/images/restaffald/24.png',
			'/assets/images/restaffald/piktogram.png',
			'/assets/images/restaffald/25.png',
		],
	}
};

export const GlobalStateContext = React.createContext(defaultGlobalState);
export const DispatchStateContext = React.createContext(undefined);

const GlobalStateProvider = ({ children }) => {
	const [state, dispatch] = React.useReducer((state, newValue) => ({ ...state, ...newValue }), defaultGlobalState);

	return (
		<GlobalStateContext.Provider value={state}>
			<DispatchStateContext.Provider value={dispatch}>{children}</DispatchStateContext.Provider>
		</GlobalStateContext.Provider>
	);
};

export default GlobalStateProvider;