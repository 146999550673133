/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect } from 'react'; // useState, 
// import Marquee from "react-easy-marquee";
import useGlobalState from '../hooks/useGlobalState';

const MarqueeNudging = ({ fraction, fractions, items }) => {
	const [state] = useGlobalState(); // , dispatch

	useEffect(() => {
		
	}, []);
	useEffect(() => {
		const interval = setInterval(() => {
		//   console.log('This will run every 5 second!');
		  document.getElementById("s" + fraction).click();
		}, 10000);
		return () => clearInterval(interval);
	// eslint-disable-next-line
	}, []);	


	return (
		<div
			style={{ display: state.activeNudge !== false ? "none" : "block" }}
			onClick={() => {
				// console.log('div clicked' + fraction)
			}}
		>
			{/* <Marquee
				duration={50000}
				background="#000"
				height="1080px"
				width={fractions === 2 ? "960px" : "640px"}
				axis="Y"
				align="center"
				pauseOnHover={false}
				reverse={false}
			>
			</Marquee > */}
			<marquee id={ "s" + fraction } direction="down" width={fractions === 2 ? "960px" : "640px"} height="1080" scrollamount="12" onClick={() => {
				// console.log('Clicked')
			}}>
				{items.map((item, index) => {
					return <img key={'img' + index} src={item} alt="" style={{ width: '320px', marginLeft: '320px', marginRight: '320px'  }} />
				})}
			</marquee>
		</div>
	)
}

export default MarqueeNudging;