import React, { useEffect } from 'react'; // useState, 
import useGlobalState from '../hooks/useGlobalState';
import { Typography } from '@material-ui/core';

const ActiveCard = props => {
	const [state] = useGlobalState(); // , dispatch
	
	useEffect(() => {
		console.log('ActionCard:' + props.index)
	}, [props.index])

	return (
		<>
			{state.settings.fractions[props.index].title ?
				<div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', width: 960, height: 1080, textAlign: 'center', padding: 180 }}>
					<div>
						<Typography variant='h1'>{state.settings.fractions[props.index].title}</Typography>
						<Typography variant='h3' style={{ marginTop: 40 }}>{state.settings.fractions[props.index].text}</Typography>
						<Typography variant='h3' style={{ marginTop: 40 }}>{state.settings.fractions[props.index].text2}</Typography>
					</div>
				</div>
				: 
				""
			}
		</>
	)
}

export default ActiveCard;