import { makeStyles } from '@material-ui/styles';

const loadStyles = makeStyles(theme => ({
	root: {
		height: '100vh',
	},
	loadBackground: {
		margin: 'auto',
		width: '100%',
		height: '100vh',
		backgroundColor: '#000'
	},
	loadContainer: {
		margin: 'auto',
		// width: '1920px',
		// height: '1080px',
		padding: 0
	},
}));

export default loadStyles;
